<template>
  <div>
    <b-card no-body>
      <!-- Search Condition -->
      <b-row class="justify-content-between">
        <b-col
          lg="3"
          md="4"
        >
          <b-form-group
            class="font-small-3"
            label="구분"
            label-for="betHistory-select"
            label-cols-lg="3"
            label-cols-md="4"
            label-align-md="left"
          >
            <b-form-select
              id="betHistory-select"
              v-model="fetchSelected1"
              class="font-small-3 half-width"
              :options="option1"
              size="sm"
              style="color:rgb(229,166,48)"
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="8"
        >
          <b-form-group
            class="font-small-3"
            label="조회일자"
            label-for="logs-period"
            label-cols-md="2"
            label-align-md="right"
          >
            <b-input-group
              id="logs-period"
              class="calendar-size font-small-3"
            >
              <b-form-datepicker
                v-model="periodFrom"
                class=""
                boundary="window"
                locale="ko"
                placeholder="시작일"
                aria-controls="example-input"
                size="sm"
                :max="periodTo"
              />
              <div class="pl-1 pr-1">
                ~
              </div>
              <b-form-datepicker
                v-model="periodTo"
                class=""
                boundary="window"
                locale="ko"
                placeholder="종료일"
                aria-controls="example-input"
                size="sm"
                :min="periodFrom"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Table -->
      <b-row class="justify-content-between">
        <!-- Table Fitler -->
        <b-col
          lg="3"
          md="4"
        >
          <b-form-group
            class="font-small-3"
            label="Show"
            label-for="betHistory-perPage"
            label-cols-lg="3"
            label-cols-md="4"
            label-align-md="left"
          >
            <b-form-select
              id="logs-perPage"
              v-model="perPage"
              class="half-width"
              size="sm"
              :options="perPageOptions"
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="8"
        >
          <b-form-group
            class="font-small-3"
            label="검색"
            label-for="logs-searchFilter"
            label-cols-md="2"
            label-align-md="right"
          >
            <b-input-group size="sm">
              <b-form-input
                id="logs-searchFilter"
                v-model="searchFilter"
                type="search"
                placeholder="검색: 상위/추천, 닉네임/이름, 아이디, 전화번호 ..."
              />
              <b-input-group-append>
                <b-button
                  :disabled="!searchFilter"
                  @click="searchFilter = ''"
                >
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!-- Table Content -->
        <b-table
          responsive
          show-empty
          hover
          small
          empty-text="No matching records found"
          :items="paginatedItems"
          :fields="fields1"
          :filter="searchFilter"
          :filter-included-fields="filterOn"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          style="text-align: center; font-size: small"
          :current-page="currentPage"
        >
          <template #cell(index)="data">
            {{ totalRows - (perPage * (currentPage - 1) + data.index) }}
          </template>

          <template #cell(site)="data">
            <div
              style="min-width: 5rem; text-align: left;"
              size="sm"
            >
              {{ data.item.site }}
            </div>
          </template>

          <template #cell(baseDate)="data">
            <div
              style="min-width: 6rem;"
              size="sm"
            >
              {{ data.item.baseDate }}
            </div>
          </template>

          <template #cell(title)="data">
            <div
              style="min-width: 6rem;"
              size="sm"
            >
              {{ data.item.title }}
            </div>
          </template>

          <template #cell(titleSub)="data">
            <div
              style="min-width: 12rem; text-align: left;"
              size="sm"
            >
              {{ data.item.titleSub }}
            </div>
          </template>

          <template #cell(category)="data">
            <div
              style="min-width: 5rem;"
              size="sm"
            >
              {{ data.item.category }}
            </div>
          </template>

          <template #cell(categorySub)="data">
            <div
              style="min-width: 5rem;"
              size="sm"
            >
              {{ data.item.categorySub }}
            </div>
          </template>

          <template #cell(userid)="data">
            <div
              style="min-width: 6rem; text-align: center;"
              size="sm"
            >
              {{ data.item.userid }}
            </div>
          </template>

          <template #cell(asisContent)="data">
            <div
              style="min-width: 11rem; text-align: right; color:rgb(138,196,146)"
              size="sm"
            >
              {{ !isNaN(Number(data.item.asisContent)) ? Number(data.item.asisContent).toLocaleString() : data.item.asisContent }}
            </div>
          </template>

          <template #cell(tobeContent)="data">
            <div
              v-b-tooltip.hover.right.v-danger="
                !isNaN(Number(data.item.tobeContent)) && !isNaN(Number(data.item.asisContent))
                  ? (Number(data.item.tobeContent) - Number(data.item.asisContent)).toLocaleString()
                  : data.item.tobeContent
              "
              style="min-width: 11rem; text-align: right; color:rgb(138,196,146)"
              size="sm"
            >
              {{ !isNaN(Number(data.item.tobeContent)) ? Number(data.item.tobeContent).toLocaleString() : data.item.tobeContent }}
            </div>
          </template>

          <template #cell(changeContent)="data">
            <div
              style="min-width: 11rem; text-align: center; color:rgb(138,196,146)"
              size="sm"
            >
              {{ data.item.changeContent }}
            </div>
          </template>

          <template #cell(memo)="data">
            <div
              style="min-width: 15rem; text-align: left; color:rgb(138,196,146)"
              size="sm"
            >
              {{ data.item.memo }}
            </div>
          </template>

          <template #cell(performer)="data">
            <div :style="{ color: data.item.performer === 'SYSTEM' ? '#ffea00' : 'inherit', minWidth: '5rem' }">
              {{ data.item.performer }}
            </div>
          </template>

          <template #cell(systemTypeDetail)="data">
            <div
              style="min-width: 5rem;"
              size="sm"
            >
              {{ data.item.systemTypeDetail }}
            </div>
          </template>

          <template #cell(adminId)="data">
            <div
              style="min-width: 5rem;"
              size="sm"
            >
              {{ data.item.adminId }}
            </div>
          </template>

          <template #cell(adminIp)="data">
            <div
              style="min-width: 5rem;"
              size="sm"
            >
              {{ data.item.adminIp }}
            </div>
          </template>

          <template #cell(createdAt)="data">
            <div
              style="min-width: 11rem; text-align: center;"
              size="sm"
            >
              {{ data.item.createdAt | formatDate('YYYY-MM-DD HH:mm:ss') }}
            </div>
          </template>
        </b-table>

        <!-- Table Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-left justify-content-left justify-content-sm-start pl-4"
          style="margin-bottom: 0.5rem;"
        >
          <span class="font-small-2">{{ pageFrom }}~{{ pageTo }} / 총 {{ pageOf }} 건 </span>
        </b-col>
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-center"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BTable,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BPagination,
  BFormSelect,
  BInputGroupAppend,
  BInputGroup,
  BFormDatepicker, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { createNamespacedHelpers } from 'vuex'
import * as moment from 'moment-timezone'
import { FETCH_LOGS_USERS_DETAIL_POINT } from '@/store/logs/action'

moment().tz('Asia/Seoul')
const fmt1 = 'YYYY-MM-DD'
const logStore = createNamespacedHelpers('logStore')

export default {
  name: 'UserTabPointHistory',
  components: {
    BRow,
    BCard,
    BTable,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BPagination,
    BFormSelect,
    BInputGroupAppend,
    BInputGroup,
    BFormDatepicker,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      // userData: JSON.parse(localStorage.getItem('userView')),
      // userIp: localStorage.getItem('ip'),
      fetchSelected1: '',
      option1: [
        { value: '', text: '전체' },
        { value: 'win', text: '당첨' },
        { value: 'bet', text: '배팅' },
        { value: 'cancel', text: '취소' },
      ],
      searchFilter: '',
      perPageOptions: [10, 25, 50, 100],
      currentPage: 1, // 현재 페이지
      perPage: 10, // 페이지 당 표시할 항목 수
      pageFrom: 0,
      pageTo: 0,
      pageOf: 0,
      totalRows: 0, // 총 메시지 수 (서버에서 얻을 수도 있습니다)
      sortBy: 'createdAt',
      sortDesc: true,
      sortDirection: 'desc',
      filter: null,
      filterOn: [],
      disabled: false,
      periodFrom: moment(new Date()).startOf('month').format(fmt1),
      periodTo: moment(new Date()).endOf('month').format(fmt1),
      searchOption: 'username',
      inputValue: '',
      fields1: [
        { key: 'index', label: 'No.' },
        { key: 'site', label: '사이트' },
        // { key: 'baseDate', label: '변경일자' },
        { key: 'createdAt', label: '변경일시' },
        { key: 'title', label: '타이틀' },
        { key: 'titleSub', label: '서브타이틀' },
        { key: 'category', label: '구분' },
        { key: 'categorySub', label: '유형' },
        { key: 'userid', label: '변경유저명' },
        { key: 'asisContent', label: '변경전' },
        { key: 'tobeContent', label: '변경후' },
        { key: 'changeContent', label: '변경내용' },
        { key: 'memo', label: '메모' },
        // { key: 'systemType', label: '타입' },
        // { key: 'systemTypeDetail', label: '타입상세' },
        { key: 'performer', label: '작업주체' },
        { key: 'adminId', label: '작업자ID' },
        { key: 'adminIp', label: '작업자IP' },
      ],
    }
  },
  computed: {
    authSite() {
      return this.$store.getters['rootStore/authSite']
    },
    siteSelected: {
      get() {
        return this.$store.state.rootStore.siteSelected
      },
      set(value) {
        this.$store.commit('rootStore/SET_SITE_SELECTED', value)
        this.fetchData()
      },
    },
    userData() {
      return this.$store.getters['rootStore/userData']
    },
    userView() {
      return this.$store.getters['rootStore/userView']
    },
    userIp() {
      return this.$store.getters['rootStore/ip']
    },
    ...logStore.mapGetters({
      fetchLogsUsersDetailPoint: 'fetchLogsUsersDetailPoint',
    }),
    // 검색필터된 Items
    filteredItems() {
      let filteredData = this.fetchLogsUsersDetailPoint
      if (this.searchFilter) {
        filteredData = filteredData.filter(item => Object.values(item).some(
          val => val && val.toString().toLowerCase().includes(this.searchFilter.toLowerCase()),
        ))
      }
      return filteredData
    },
    // 검색필터된 Items => 페이징처리된 Item
    paginatedItems() {
      const totalFilteredItems = this.filteredItems.length
      const start = totalFilteredItems - (this.currentPage * this.perPage)
      const end = start + this.perPage

      const paginatedItems = this.filteredItems.slice(Math.max(0, start), end).reverse()

      this.totalRows = totalFilteredItems
      this.pageFrom = (this.currentPage - 1) * this.perPage + 1 // 현재 페이지의 첫 번째 항목
      this.pageTo = Math.min(this.totalRows, this.currentPage * this.perPage) // 현재 페이지의 마지막 항목 또는 전체 항목 수 중 작은 값
      this.pageOf = this.totalRows

      return paginatedItems
    },
  },
  watch: {
    periodFrom() {
      this.fetchData()
    },
    periodTo() {
      this.fetchData()
    },
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    ...logStore.mapActions({
      $fetchLogsUsersDetailPoint: FETCH_LOGS_USERS_DETAIL_POINT,
    }),
    async fetchData() {
      await this.$fetchLogsUsersDetailPoint({
        site: this.siteSelected,
        periodFrom: this.periodFrom,
        periodTo: this.periodTo,
        userid: this.userView.userid,
        systemTypeDetail: 'POINT',
      })
    },
  },
}

</script>

<style scoped>
.per-page-selector {
  width: 90px;
}
.divSelecter {
  width: 180px;
}
</style>
